.select-wrapper {
  position: relative;
}
.options {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  right: 0;
  left: 0;
  border: 1px solid grey;
  border-radius: 3px;
  max-height: 50vh;
}
.option {
  cursor: pointer;
  transition: all 0.4s;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid #eee;
}
.option:hover {
  cursor: pointer;
  background-color: #007bff77;
}

.option.selected {
  color: #fff;
  background-color: var(--primary);
}
.option:empty {
  padding: 0.9rem;
}
.option:last-child {
  border-bottom: none;
}
