.modal__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #44444411;
  z-index: 90;
  transition: opacity 0.3s linear;
}
.enter.modal__wrapper {
  opacity: 0;
}
.exit.modal__wrapper {
  opacity: 0;
}
.enter-active.modal__wrapper {
  opacity: 1;
}
/*  */
.modal__area {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  box-shadow: var(--bs5);
  padding-bottom: 0.75rem;
  transition: all 0.3s ease-in-out;
}
.enter .modal__area {
  opacity: 0;
  transform: translate(-50%, -100%);
}
.enter-active .modal__area {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.exit .modal__area {
  opacity: 0;
  transform: translate(-50%, 100%);
}
/*  */
.modal__body {
  max-height: 70vh;
  padding: 0 1.25rem;
}
/*  */
.modal__header {
  display: flex;
  width: 100%;
}
.close-icon {
  display: inline-flex;
  margin: 2px 2px 0 auto;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 9px;
}
svg.close {
  fill: #555;
}
svg.close:hover {
  fill: black;
}
