:root {
  --h: 40px;
  --bs5: 0 0 5px rgb(0 0 0 / 30%);
  --bs4: 0px 1px 4px -1px rgb(0 0 0 / 30%);
}
html {
  font-size: 16px;
}

/* Scroll */
.scroll {
  overflow-y: auto;
  scrollbar-width: thin;
}
.scroll-lock {
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}
::-webkit-scrollbar-thumb:hover {
  background: #ababab;
}
::-webkit-scrollbar-thumb:active {
  background: #605555;
}
/*  */

/* General */
.a-center {
  width: auto;
  text-align: center;
  margin: 0 auto;
}
.fix-center {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.a-end {
  margin-left: auto;
}
.a-start {
  margin-right: auto;
}

.link {
  color: blue;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.box {
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}

.hl {
  border-bottom: 1px solid #ddd;
  margin: 0.25rem 0;
}
.vl {
  border-right: 1px solid #ddd;
  margin: 0.25rem;
}

.grid-even {
  display: grid;
  grid-template-columns: 50% 50%;
}

.sticky {
  position: sticky;
  top: 0;
}
.input-width {
  max-width: 231px;
}
.pointer {
  cursor: pointer;
}
/*  */
/* Bootstrap Override */

.form-control.sm {
  padding: 0.25rem 0.5rem !important;
}
.form-control.num {
  font-size: 14px;
  width: 20ch;
}
.form-control:focus-within {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.alert {
  padding: 0.25rem !important;
  word-wrap: break-word;
  font-size: 0.75rem;
  margin-bottom: 0 !important;
}
/*  */

/* Custom Elements */
.img-rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 0;
}
.img-rounded-sm {
  object-fit: cover;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  margin: 0;
}
.toolbar {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  top: 10px;
  right: 10px;
  border-radius: 3px;
}

.loc {
  position: absolute;
  right: 10px;
  top: 128px;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  background-color: white;
  color: #666666;
  border-radius: 2px;
  box-shadow: var(--bs4);
  cursor: pointer;
}
.loc:hover {
  background-color: white;
  color: #212529;
}

.tree-sticker {
  position: fixed;
  bottom: 5px;
  left: 5px;
  display: inline-flex;
  background-color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  box-shadow: var(--bs4);
}
.sticker {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  margin: 0 0.25rem;
}

.sticker-label {
  grid-column: 1 / span 2;
  font-size: 14px;
  line-height: 0.5;
  margin-bottom: 4px;
}
.sticker-img {
  width: 26px;
}
.sep-comma:not(:last-child)::after {
  content: ", ";
}
/*  */
/* Media Queries */
@media only screen and (max-width: 584px) {
  :root {
    --h: 72px;
  }
  .toolbar {
    flex-direction: column;
  }
  html {
    font-size: 18px;
  }
  .tree-sticker {
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    border-radius: 0;
    justify-content: space-around;
  }
}
.nav-tabs {
  border-bottom: 1px solid blue !important;
}
.nav-tabs .nav-link.active {
  color: white;
  background-color: blue;
  border-color: var(--primary) var(--primary) var(--primary);
}
.nav-link:hover {
  border-bottom: var(--primary);
}

.navbar {
  padding: 0.2rem 0.5rem 0.2rem 0.5rem !important;
}
.navbar-toggler {
  border: none;
}

.nav-link {
  cursor: pointer;
}
.badge {
  padding-top: 1px;
  padding-left: 5px;
  padding-bottom: 1px;
  padding-right: 5px;
}

.search-box:focus-within {
  box-shadow: 0 0 0 0.2rem var(--blueshadow);
}

.search-box__input {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
}

.bg-red {
  background-color: rgb(236, 82, 76) !important;
  color: #fff;
}
.bg-green {
  background-color: rgb(89, 243, 100) !important;
  /* color: #fff; */
}
.bg-yellow {
  background-color: rgb(233, 233, 29) !important;
}
.bg-blue {
  background-color: rgb(63, 102, 182) !important;
  color: #fff;
}
.bg-lightGreen {
  background-color: #a6f0b2 !important;
}
.bg-lightBrown {
  background-color: #f0a6a6 !important;
}
.bg-grey {
  background-color: gray !important;
  /* color: white; */
}
/*  */
