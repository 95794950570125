header {
  position: relative;
  background: rgba(255, 255, 255);
  padding: 0 0.25rem;
  margin: 0;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  font-size: 1.1em;
  height: 40px;
}

.drawer {
  z-index: 10;
  position: absolute;
  top: 100%;
  min-width: 200px;
  left: 0;
  box-shadow: 3px 3px 3px rgba(0 0 0 / 7%);
}

.drawer.enter {
  transform: translateX(-100%);
  opacity: 0;
}
.drawer.enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 200ms ease-in-out;
}
.drawer.exit {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 200ms ease-in-out;
}

.drawer ul {
  list-style: none;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: rgba(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.drawer li {
  text-transform: uppercase;
  font-size: 1em;
  padding: 0 0.75rem 0.5rem;
}

.item {
  color: #666;
  text-decoration: none;
}
.item:hover {
  color: #333;
}

.logo {
  font-size: 1.3em;
  margin: 0;
  text-decoration: none;
  font-weight: 500;
  /* color: blue; */
}

.dd {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--bs5);
  z-index: 20;
  padding: 0.5rem 0.75rem;
  width: 15rem;
  transform-origin: top right;
}
.dd.enter {
  transform: scale(0, 0);
  opacity: 0;
}
.dd.enter-active {
  transform: scale(1, 1);
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.dd.exit {
  transform: scale(0, 0);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
