.side-window {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(220px + 1.5rem);
  z-index: 9;
  background-color: white;
  border-radius: 6px;
  box-shadow: var(--bs5);
  padding-bottom: 0.75rem;
}

.side-window.enter {
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
}

.side-window.enter-active {
  opacity: 1;
  transform: translateX(0);
  pointer-events: visible;
  transition: all 300ms ease-in-out;
}

.side-window.exit {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
  transition: all 300ms ease-in-out;
}

.side-window__content {
  max-height: calc(100vh - 135px);
  padding: 0 0.75rem;
}

.modal__header {
  display: flex;
  width: 100%;
}
.close-icon {
  display: inline-flex;
  margin: 0 0 0 auto;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 9px;
}
svg.close {
  fill: #555;
}
svg.close:hover {
  fill: black;
}
/* 
svg{
  fill: #a6f0b2;
} */
